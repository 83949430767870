html {
    --antd-wave-shadow-color: inherit !important;
}

.ant-input {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    height: 36px;
    border-radius: 5px;
    box-shadow: none !important;
    color: #5B5E60;
    font-size: 14px;
}


/* Menu */

.ant-menu {
    background-color: transparent;
    border-bottom: none;
}

.ant-menu:hover {
    border-bottom: none;
}

.ant-menu-item .ant-menu-item-icon {
    vertical-align: middle;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-hidden .ant-menu-item .anticon+span {
    color: #FFFFFF;
}

.ant-menu.ant-menu-sub .ant-menu-title-content a {
    color: #5B5E60;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.ant-menu-item-selected a {
    color: #926BCB !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active span {
    color: #926BCB !important;

}

.ant-menu-submenu .ant-menu-item:hover .ant-menu-title-content a {
    color: #926BCB !important;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical .ant-menu-item-selected {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 243, 253, var(--tw-bg-opacity));
}

.ant-menu-submenu-title .ant-menu-item-icon {
    color: #FFFFFF;
    float: right;
    font-size: 16px;
}

.ant-menu-horizontal>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-title-content {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    padding-right: 8px;
}

.ant-menu-submenu-popup>.ant-menu {
    box-shadow: 4px 4px 18px 1px rgba(91, 94, 96, 0.2);
    padding: 20px 0;
    background-color: #fff !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background: #FFFFFF;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical .ant-menu-item {
    padding: 0 23px !important;
    height: 33px !important;
    line-height: 33px !important;
}

.ant-menu-dark .ant-menu-item {
    color: #5B5E60 !important;
}

/*Drawer*/


.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
    padding: 0 !important;
}

.ant-menu.ant-menu-dark {
    background: #7450B2;
}

.ant-drawer .ant-menu-inline.ant-menu-root .ant-menu-item {
    padding: 0;
    padding-left: 30px !important;
    height: 46px;
}


.ant-drawer .ant-menu-light .ant-menu-item:hover,
.ant-drawer .ant-menu-light .ant-menu-item-active,
.ant-drawer .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    background-color: rgba(173, 129, 234, 0.15);
}

/* .ant-menu-inline .ant-menu-submenu {
    padding-left: 30px !important;
} */

.ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-title {
    padding-left: 30px !important;
}

.ant-drawer .ant-menu-inline.ant-menu-root .ant-menu-submenu .ant-menu-item .ant-menu-title-content {
    padding-left: 25px !important;
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #F0F0F0 !important;
    transition: none !important;
}

.ant-menu-submenu-selected {
    color: #F0F0F0 !important;
    transition: none !important;
}

.ant-drawer .ant-menu-submenu-title .anticon {
    display: none;
}

.ant-drawer .ant-menu.ant-menu-root {
    padding: 20px 8px 0 8px;
    border-right: none;
}

.ant-drawer .ant-menu-submenu-title .anticon+span {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #F0F0F0;
    margin-left: 0;
    text-transform: capitalize;
}

.ant-drawer .ant-menu-sub.ant-menu-inline {
    background: #7450B2;
}

.ant-drawer .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(173, 129, 234, 0);
}

.ant-drawer .ant-menu-inline .ant-menu-item::after {
    border-right: none !important;
}

.ant-drawer .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;
    transition: none;
    background-color: transparent !important;
    height: 46px;
}

.ant-drawer .ant-menu-submenu-expand-icon,
.ant-drawer .ant-menu-submenu-arrow {
    color: #F0F0F0;
}

.ant-drawer .ant-menu.ant-menu-sub .ant-menu-title-content {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #F0F0F0;
    text-transform: capitalize;
}

.ant-drawer .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-drawer .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: #F0F0F0 !important;
}

.ant-drawer .ant-drawer-wrapper-body {
    height: auto !important;
}

.ant-drawer .ant-menu-submenu .ant-menu-item:hover .ant-menu-title-content a {
    color: #F0F0F0 !important;
}

/*ant table*/

.ant-table-pagination.ant-pagination {
    margin: 30px 0;
}

.ant-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
}

.ant-table .ant-table-thead>tr>th,
.ant-table .ant-table-thead>tr>th .ant-table-column-title {
    color: #5B5E60;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    border: none;
    background-color: transparent;
    max-width: 200px;
}

.ant-table-thead>tr>th {
    background-color: transparent !important;
}

.ant-table .ant-table-tbody>tr>td {
    color: #5B5E60;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 16px;
    border-bottom: none;
    height: 40px;
}

.ant-table .ant-table-tbody>tr>td a {
    color: #7450B2;
    font-weight: bold;
}
.ant-table .ant-table-tbody>tr>td a:hover {
    color: #926BCB;
}
.ant-table .ant-table-tbody>tr>td button:disabled a {
    cursor: not-allowed;
    opacity: 0.5;
}

.ant-table .ant-table-tbody>tr>td a img {
    max-width: 22px;
}

.ant-table .ant-table-row-indent {
    margin-right: 5px;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
    background: rgba(173, 129, 234, 0.1) !important;
}

.ant-table-tbody>tr.ant-table-row-selected:hover {
    background: rgba(173, 129, 234, 0.1) !important;
}

.ant-table button.ant-btn.ant-btn-default.ant-dropdown-trigger {
    padding: 0;
    border: none;
    color: #926BCB;
}


.ant-table td.ant-table-cell.ant-table-cell-with-append .anticon {
    color: #926BCB;
    vertical-align: middle;
    margin-right: 20px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #926BCB;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #926BCB;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: #926BCB;
    box-shadow: none !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
    color: #926BCB !important;
    background-color: #f5f5f5 !important;
}

.ant-table-filter-dropdown-btns .ant-btn-link {
    color: #926BCB;
}

.ant-table-filter-dropdown-btns .ant-btn-primary {
    border-color: #926BCB;
    background: #926BCB;
}

.ant-table-filter-trigger.active {
    color: #926BCB;
}

.ant-table-tbody tr.ant-table-expanded-row>td,
.ant-table-tbody tr.ant-table-expanded-row:hover>td {
    background: transparent;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: rgba(173, 129, 234, 0.1) !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: transparent;
}



.ant-pagination-item-active {
    border-color: #926BCB;
}

.ant-pagination-item-active a {
    color: #926BCB;
}

.ant-pagination-item:hover {
    border-color: #926BCB;
    transition: all 0.3s;
}

.ant-pagination-item:hover a {
    color: #926BCB;
}

.ant-pagination-item:focus-visible {
    border-color: #926BCB;
    transition: all 0.3s;
}

.ant-pagination-item:focus-visible a {
    color: #926BCB;
}

.ant-pagination-item-active {
    border-color: #926BCB;
}

.ant-pagination-item-active a {
    color: #926BCB;
}

.ant-pagination-item-active:hover {
    border-color: #926BCB;
}

.ant-pagination-item-active:focus-visible {
    border-color: #926BCB;
}

.ant-pagination-item-active:hover a {
    color: #926BCB;
}

.ant-pagination-item-active:focus-visible a {
    color: #926BCB;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: #926BCB;
    border-color: #926BCB;
}

/*ant checkbox*/

.ant-checkbox {
    color: #5B5E60;
}

.ant-checkbox-inner {
    border: 2px solid #5B5E60;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7450B2;
    border-color: #7450B2;
}

.ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #C7C9CA !important;
    background-color: #C7C9CA !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #5B5E60;
}

.ant-checkbox-checked::after {
    border: 1px solid #5B5E60;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #7450B2;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    left: 2px;
}


/*ant form*/
.ant-form-item {
    margin-bottom: 15px;
}

.ant-form-item-control-input {
    min-height: 34px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #E0E0E0;
}

.ant-input:focus,
.ant-input-focused {
    border: 1px solid #7450B2 !important;
    color: #7450B2 !important;
    box-shadow: none !important;
    border-color: #7450B2 !important
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #7450B2 !important;
    box-shadow: none !important;
}

.ant-select.ant-select-focused .ant-select-selection-item {
    color: #7450B2 !important;
}

.ant-select.ant-select-focused .ant-select-arrow {
    color: #7450B2 !important;
}


/*File Uploader*/
.ant-upload.ant-upload-drag {
    background: #F7F3FD;
    border-radius: 5px;
    border: 1px solid transparent !important;
}

.ant-upload.ant-upload-drag:hover {
    background: rgba(173, 129, 234, 0.2);
    border: 1px dashed #926BCB !important;
}

.ant-upload-drag-option {
    height: 115px;
    background: rgba(173, 129, 234, 0.15);
    border: 1px dashed transparent;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all ease 0.5s;
}

.ant-form-item-has-error .ant-upload-drag-option {
    height: 115px;
    background: rgba(215, 63, 70, 0.05);

    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ant-form-item-has-error .ant-upload-drag-option:hover {
    background: rgba(215, 63, 70, 0.1);
    border: 1px dashed #D73F46;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #7450B2;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #5B5E60;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
    color: #7450B2;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
}

.ant-upload-list .ant-upload-list-item {
    margin-top: 0;
}

/*ant tab*/

.ant-tabs-tab {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5B5E60;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #926BCB;
}

.ant-tabs-ink-bar {
    background: #926BCB !important;
    height: 4px !important;
}

.ant-tabs-tab {
    padding: 12px 18px;
}

.ant-tabs-tab .ant-tabs-tab-btn:focus,
.ant-tabs-tab .ant-tabs-tab-remove:focus,
.ant-tabs-tab .ant-tabs-tab-btn:active,
.ant-tabs-tab .ant-tabs-tab-remove:active,
.ant-tabs-tab:hover {
    color: #926BCB;
}

.ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 25px;
}

/*ant radio*/

.ant-radio-inner {
    border-width: 2px;
    width: 18px;
    height: 18px;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #5B5E60;
}

.ant-radio-inner::after {
    background-color: #7450B2;
}

.ant-radio-checked::after {
    border: 1px solid #5B5E60;
}

.ant-radio-group span.ant-radio+* {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5B5E60;
}

.ant-radio-wrapper {
    margin-right: 15px;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: #5B5E60;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #5B5E60;
    border-color: #5B5E60;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #5B5E60;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #5B5E60;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #5B5E60;
    border-color: #5B5E60;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #5B5E60;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #5B5E60;
    border-color: #5B5E60;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: #5B5E60;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #5B5E60;
    border-color: #5B5E60;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #5B5E60;
    border-color: #5B5E60;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    background: #5B5E60;
    border-color: #5B5E60;
}

/*selection Box*/

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 1px 8px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border-color: #7450b2 !important;
}

.ant-select-multiple .ant-select-selection-item {
    background: rgba(173, 129, 234, 0.08);
    border: 1px solid #7450B2;
    color: #7450B2;
    -webkit-margin-end: 8px;
    margin-inline-end: 8px;
    border-radius: 0;
    padding: 0 10px 0 15px;
    font-size: 14px;
    font-weight: 400;
}

.ant-select-multiple .ant-select-selection-item-remove>.anticon {
    color: #7450B2;
}

.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-search .ant-select-selector {
    padding: 8px;
}

.ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector {
    padding: 8px;
}

/*ant calendar*/

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: #7450B2 !important;
}

.ant-picker {
    height: 34px;
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: #7450B2 !important;
    box-shadow: none !important;
}

.ant-picker-input>input:hover {
    border-color: #7450B2 !important;
}

.ant-picker-range .ant-picker-active-bar {
    background: #7450B2 !important;
}

.ant-picker-ranges .ant-picker-preset>.ant-tag-blue {
    color: #7450B2 !important;
}

.ant-picker-panel-focused {
    border-color: #7450B2 !important;
}

.ant-picker-header-view button:hover {
    color: #7450B2 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #7450B2 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #7450B2 !important;
}

.ant-picker-today-btn {
    color: #7450B2 !important;
}

.ant-picker-input>input {
    height: 34px;
    color: #5B5E60;
    font-size: 14px;
}

.ant-picker-suffix {
    color: #926BCB;
}

/* ant tag */

.ant-tag-box .ant-tag {
    background: rgba(173, 129, 234, 0.08);
    border: 1px solid #AD81EA;
    border-radius: 0;
    line-height: 24px;
    padding: 0 10px 0 15px;
    font-size: 14px;
    font-weight: 400;
    color: #AD81EA;
    margin-bottom: 10px;
}

.ant-tag-box .ant-tag:hover {
    background: rgba(173, 129, 234, 0.15);
    border: 1px solid #7450B2;
    color: #7450B2;
}

.ant-tag-box .ant-tag-close-icon {
    color: #AD81EA;
    vertical-align: middle;
}

.ant-tag-box .ant-tag:hover .ant-tag-close-icon {
    color: #7450B2;
}

/*ant serachbar*/

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
    border-color: #7450B2 !important;
    box-shadow: none !important;
}

.ant-input:hover {
    border-color: #7450B2 !important;
}

.ant-input-search .ant-input {
    color: #5B5E60;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
}

.ant-message .anticon {
    top: -2px;
}

.ant-select-multiple .ant-select-selection-placeholder {
    padding-left: 3px;
}

.ant-form-item-explain-error {
    color: #D73F46 !important;
    font-size: 13px;
    font-family: 'Effra';
    margin: 5px 0;
}

/*sidebar menu*/

.ant-drawer .ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content a,
.ant-drawer .ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #F0F0F0;
    font-family: 'Effra';
}

.ant-drawer .ant-menu-inline.ant-menu-root .ant-menu-item:hover {
    background: rgba(173, 129, 234, 0.15);
}

.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table,
.ant-table-tbody>tr>td>.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table {
    margin: -8px -16px -16px 32px;
}

.ap-table .ant-table .ant-table-container .ant-table-body {
    overflow-y: auto !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5 !important;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
}
.ant-table-column-sorters {
    justify-content: inherit;
}
.ant-table .ant-table-thead>tr>th .ant-table-column-title {
    max-width: max-content;
}
.ant-table .ant-table-thead>tr>th.ap-table-head .ant-table-column-sorters {
    justify-content: center;
}

.ant-table-expanded-row .ant-table table {
   margin-bottom: 10px;
}